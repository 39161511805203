import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import sortBy from "lodash.sortby";
import React from "react";
import { makeFilters } from "../components/Listing/config";
import Listing from "../components/Listing/Listing";
import MakesResultsArea from "../components/Listing/MakesResultsArea";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import { SanityLessonsPage } from "../model/lessons";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import { levelToNumber } from "../utils/level-util";

export const pageQuery = graphql`
  query MakeListingPage(
    $_id: String!
    $language: String
    $navLanguage: String
  ) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    listings: sanityMakeListingPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      _rawSideBarText(resolveReferences: { maxDepth: 4 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    content: allSanityMake(
      filter: { language: { eq: $language } }
      sort: { fields: [_createdAt], order: ASC }
    ) {
      nodes {
        _id
        _type
        language
        slug {
          current
        }
        title
        description
        image {
          ...SanityImage
        }
        level
        deviceFeatures {
          _id
          name
        }
        ...MakeTopics
        programmingLanguages
      }
    }
    thirdPartyResources: sanityThirdPartyMakeConfig {
      thirdPartyResourceOrder {
        _id
        url
        title
        image {
          ...SanityImage
        }
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "projects" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const MakeListingPage = ({
  pageContext,
  data: { menus, campaigns, content, listings, thirdPartyResources },
  location
}: MakeListingPageProps) => {
  const { _rawSideBarText, title } = listings;
  // Can't easily do this bit in GraphQL as the values are beginner/intermediate/advanced.
  const makesByLevelThenCreatedAt = sortBy(content.nodes, m =>
    levelToNumber(m.level)
  );
  return (
    <PageLayout
      siteArea={SiteArea.MAKE_IT_CODE_IT}
      metadata={{
        title,
        alternates: pageContext.alternates,
        page: listings
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <Listing
        page={listings}
        campaigns={campaigns.nodes}
        title={title}
        config={makeFilters}
        sideBarText={_rawSideBarText}
        contents={makesByLevelThenCreatedAt}
        renderRows={MakesResultsArea}
        thirdPartyResourcesTitleId="other-projects"
        thirdPartyResources={
          thirdPartyResources && thirdPartyResources.thirdPartyResourceOrder
        }
      />
    </PageLayout>
  );
};

export default MakeListingPage;

interface MakeListingPageProps {
  data: {
    menus: GlobalMenus;
    campaigns: { nodes: SanityCampaign[] };
    listings: SanityLessonsPage;
    content: any;
    thirdPartyResources: any;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
