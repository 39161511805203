import React from "react";
import { Col, Row } from "../../components/Grid";
import { SanityMake } from "../../model/makes";
import MakeCard from "../MakeCard/MakeCard";
import styles from "./Listing.module.scss";

const MakesResultsArea = ({ results }: MakesResultsAreaProps) => {
  return (
    <Row>
      {results.map(d => (
        <Col xs={6} sm={6} md={4} className={styles.cardListingCol}>
          <MakeCard key={d._id} make={d} className={styles.cardListingCard} />
        </Col>
      ))}
    </Row>
  );
};

interface MakesResultsAreaProps {
  results: SanityMake[];
}

export default MakesResultsArea;
